<template>
  <v-container class="points-polv-container px-0 px-sm-auto">
    <CategoryTitle :category="category" :add-container="false" />

    <category-block
      :target="category"
      :container="false"
      position="position1"
      class="category-block category-block-1"
    />
    <category-block
      :target="category"
      :container="false"
      position="position3"
      class="category-block category-block-3"
    />

    <div
      class="fidelity-card-wrapper font-weight-bold mt-3 pa-4 grey lighten-2 rounded-sm text-h5"
    >
      <span class="fidelity">{{ $t("pointsPolv.pointsPolvBalance") }}</span>
      <span class="fidelity">{{ $n(pointsCounter / 100, "currency") }}</span>
    </div>

    <v-form
      ref="pointsPolvForm"
      @submit.prevent.stop="polverizePoints"
      class="d-flex flex-column justify-center mt-5"
    >
      <span class="d-block text-h4">
        {{ $t("pointsPolv.pointsBalance") }} {{ pointsToConvert }}
      </span>

      <v-text-field
        class="white"
        outlined
        dense
        :rules="isNumberRule"
        required
        v-model="pointsToConvertModel"
        :label="$t('pointsPolv.pointsToPolverize')"
        :placeholder="$t('pointsPolv.pointsToPolverize')"
      >
        <template v-slot:append>
          <v-btn
            class="applyBtn mt-0"
            tile
            depressed
            height="38"
            medium
            type="submit"
            :disabled="pointsToConvertModel <= 0"
            color="primary"
            >{{ $t("pointsPolv.applyBtn") }}</v-btn
          >
        </template>
      </v-text-field>
    </v-form>
    <ResponseMessage :response="responseMessage" />

    <category-block
      :target="category"
      :container="false"
      position="position2"
      class="category-block category-block-2"
    />
    <ebsn-meta :target="category" path="category_info.FOOTER_TEXT" tag="div" />
  </v-container>
</template>
<style lang="scss">
.points-polv-container {
  .v-input__append-inner {
    margin-top: 0 !important;
  }
  .applyBtn {
    margin-top: 0;
    top: 1px;
    right: -11px;
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";

import categoryMixins from "~/mixins/category";

import customService from "@/service/customService";

import { mapGetters, mapActions } from "vuex";
import { isNumber } from "~/validator/validationRules";

// import get from "lodash/get";

export default {
  name: "PointsPolv",
  components: { CategoryTitle, ResponseMessage },
  mixins: [categoryMixins],
  data() {
    return {
      pointsCounter: 0,
      pointsToConvert: 0,
      pointsToConvertModel: 0,
      responseMessage: {},
      isNumberRule: [isNumber()]
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    calcPoints() {
      for (let i = 0; i < this.user.userPointsCounters.length; i++) {
        if (this.user.userPointsCounters[i].codInt == "86000004") {
          this.pointsCounter = this.user.userPointsCounters[i].value;
        } else if (this.user.userPointsCounters[i].codInt == "98000002") {
          this.pointsToConvert = this.user.userPointsCounters[i].value;
        }
      }
    },
    async polverizePoints() {
      this.responseMessage = {};
      try {
        if (this.$refs.pointsPolvForm) {
          if (this.pointsToConvertModel > 0) {
            if (this.pointsToConvertModel > this.pointsToConvert) {
              this.pointsToConvertModel = this.pointsToConvert;
            }

            let res = await customService.polverizePoints(
              this.pointsToConvertModel
            );

            this.responseMessage = res.response;
            if (res.response && res.response.status == 0) {
              this.getCart();
              this.pointsToConvertModel = 0;
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
  mounted() {
    this.calcPoints();
  }
};
</script>
